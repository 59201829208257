.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #2e93c6;
  color: #2e93c6;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #2e93c6;
}

.paginationActive a {
  color: white;
  background-color: #2e93c6;
}
